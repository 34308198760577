.footer {
    background-color: #F5F5F5;
    height: 350px;
  }
  
  .footer-links {
    text-decoration: none;
  }
  .place{
    margin-right: 12px;
  }
  .btn-submit{
    background-color: var(--primary-blue-color) ;
    color: var(--primary-font-color);
  }
  .btn-submit:hover{
    background-color: var(--secondary-pink-color) ;
    color: var(--primary-bg-color);
  }
  .btn-submit:active{
    background-color: var(--secondary-pink-color) ;
    color: var(--primary-bg-color);
  }
  .contact-form{
    height: 100px;
  }
  .textarea-height{
    height: 75px;
  }
  .gmailIcon{
    margin-left: 5px;
  }
  .latest-footer{
    margin-top: 20px;
    padding: 20px 0;
  }
  @media (max-width: 768px) {
    .footer {
      background-color: #F5F5F5;
      height: 600px;
    }
    .contact-info{
      padding-top: 140px;
    }
    .footerIcon{
      height: 30px;
    }
    .latest-footer{
      margin-top: 60px;
    }
  }