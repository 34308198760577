@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.left-testmonial{
    /* margin-left: 160px; */
    /* margin-right: 130px; */
position: relative ;
/* right: 900px; */
right: 130px;
}
.right-testmonial{
    /* margin-right: 200px; */
    /* margin-left: 130px; */
    position: relative ;
left: 130px;
}
.center-testmonial{
    padding-bottom: 100px;
    z-index: 10;
}
.testimonials-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
@media (min-width: 768px) and  (max-width: 992px) {
    .left-testmonial{
        /* margin-left: 12px; */
    }
    .right-testmonial{
        /* margin-right: 120px; */
    }
}
@media (max-width: 768px) {
    .left-testmonial{
        /* margin-left: 0px; */
        /* padding-bottom: 150px; */
    }
    .right-testmonial{
        /* margin-right: 0px; */
        /* padding-bottom: 100px; */

    }
    .center-testmonial{
        /* padding-bottom: 150px; */
    }
}