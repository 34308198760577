.testimonials-slider {
  /* width: 100vw; */
  height: 100vh;
  /* max-width: 1200px;  */
  /* margin: 0 auto; */
  /* padding: 50px 0; */
   /* Add padding for spacing around the slider */
  position: relative;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding-top: 100px; */
  /* padding-right: 300px; */

}
.slick-list{
  height: 500px;
}

.slick-dots {
  bottom: -30px;
  position: relative;
  top: -50px; 
  /* Position the dots below the slider */
}

.slick-dots li button:before {
  font-size: 12px;
  color: var(--primary-blue-color); /* Use the primary color for dots */
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  color: var(--primary-blue-color); /* Highlight the active dot */
  opacity: 1;
}

.testimonial-group {
  display: flex;
  justify-content: space-between;
  /* padding: 20px; */
  background-color: white; /* Background color for the testimonial group */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.testimonial-group > div {
  flex: 1;
  /* margin: 0 10px;  */
  /* Spacing between testimonials */
  /* padding: 20px; */
  border-radius: 10px; /* Match the border radius for consistency */
}

.testimonial-group > div:first-child {
  /* margin-left: 0;  */
  /* Remove left margin for the first testimonial */
}

.testimonial-group > div:last-child {
  /* margin-right: 0;  */
  /* Remove right margin for the last testimonial */
}
