.react-calendar {
  /* margin: auto; */
  box-shadow: 0px 0 10px gray;
  border-radius: 20px;
  border: 0px solid;
  width: 90% !important;
}
@media only screen and (max-width: 768px) {
  .react-calendar {
    width: 100% !important;
  }
}

.react-calendar__tile--active {
  background-color: var(--secondary-green-color) !important;
}

.react-calendar__tile--active:enabled:hover {
  background-color: var(--secondary-green-color) !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #1f1b13 !important;
}

.react-calendar__tile--now {
  background-color: transparent !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}
.react-calendar__tile:enabled:hover {
  background-color: var(--secondary-green-color);
  color: var(--primary-bg-color);
  border-radius: 6px;
}
.react-calendar__tile--range {
  background: var(--secondary-green-color);
  color: var(--primary-bg-color);
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: var(--secondary-green-color);
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: var(--secondary-green-color);
  color: white;
}
.today {
  font-weight: bold;
  color: var(--primary-font-color);
  background-color: transparent;
}
.today:hover{
  color: var(--primary-font-color);
  background-color: transparent;
}