.reservation-page{
    background-color: var(--primary-blue-color);
    padding: 50px;
}
.reservation-bg{
    background-color: #F5F5F5;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 50px;
    text-align: right;
}