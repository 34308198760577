.btn-primary-blue {
    background-color: var(--primary-blue-color);
    color: white;
    font-family: "Tajawal", sans-serif;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn-primary-blue:hover {
    background-color: var(--primary-blue-hover-color);
}

.btn-primary-blue:active {
    background-color: var(--primary-blue-active-color);
}

h1,
p {
    font-family: "Tajawal", sans-serif;
}

.rtl-text {
    direction: rtl;
    text-align: right;
}

h1.rtl-text {
    font-size: 3rem;
    font-weight:normal;
    font-family: "Tajawal", sans-serif;
}

p.rtl-text {
    font-size: 1.2rem;
    font-family: "Tajawal", sans-serif;
}


@media (max-width: 767px) {
    h1.rtl-text {
        font-size: 2rem;
    }

    p.rtl-text {
        font-size: 1rem;
    }
}