.header_section {
  background-color: #ffffff;
}

.header_section .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.header_section .nav_container {
  margin: 0 auto;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
  padding: 3px 15px;
  margin: 10px 15px;
  color: #272727;
  text-align: center;
  text-transform: uppercase;
  position: relative; /* For positioning the ::after pseudo-element */
}

.custom_nav-container .navbar-nav .nav-item .nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: var(--primary-blue-color);
  transition: width 0.3s ease-in-out;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.custom_nav-container .navbar-nav .nav-item .nav-link:hover::after,
.custom_nav-container .navbar-nav .nav-item .nav-link.active::after {
  width: 100%;
}

a {
  font-family: "Tajawal", sans-serif;
}

a, a:hover, a:focus {
  text-decoration: none;
}

a:hover, a:focus {
  color: initial;
}

.btn, .btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom_nav-container .nav_search-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
}

.navbar-brand span {
  font-size: 24px;
  font-weight: 700;
  color: #272727;
  text-transform: uppercase;
}

.custom_nav-container {
  z-index: 99999;
  padding: 5px 0;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span {
  display: block;
  width: 32px;
  height: 4px;
  background-color: #000000;
  border-radius: 15px;
  margin: 7px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::before,
.custom_nav-container .navbar-toggler span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  border-radius: 15px;
  top: -10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::after {
  top: 10px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span::before,
.custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 0;
}

.quote_btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.quote_btn-container a {
  color: #151515;
  margin-right: 25px;
  text-transform: uppercase;
}

.quote_btn-container a span {
  margin-right: 5px;
}


@media (max-width: 768px) {
  .custom_nav-container .navbar-nav {
    flex-direction: column;
  }
  .custom_nav-container .nav-item {
    margin-bottom: 10px;
  }
  .custom_nav-container .nav-link {
    padding: 10px;
  }
}
/* .sticky {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1140px; 
  margin: 0 auto;
  z-index: 99999;
  background-color: #ffffff; 
} */