.article-card{
    max-width: 350px;
    margin: auto ;
    overflow: hidden;
    text-decoration: none;
    color: black;
}

.article-img{
    max-height: 200px;
    object-fit: cover;
}
.card-title{
    color: var(--primary-font-color);
}
.card-text{
    color: #757373;
}
