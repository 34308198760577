@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

:root{
  --primary-blue-color: #ADD8E6;
  --primary-blue-color: #ADD8E6;
  --secondary-green-color: #8DA57E;
  --secondary-pink-color: #F4C2C2;
  --secondary-gray--color: #D5D8DC;
  /* Font Colors */
  --primary-font-color: #333333;
  --secondary-font-color: #2C3E50;
  /* Background Colors */
  --primary-bg-color: #FFFFFF;
  --secondary-bg-color: #F0F0FC;
  --third-bg-color: #F5F5F5;
}

p{
  font-family: "Tajawal", sans-serif;
}

.primary-blue-background {
  background-color: var(--primary-blue-color) !important;
  font-family: "Tajawal", sans-serif !important;
}

.secondary-pink-background {
  background-color: var(--secondary-pink-color) !important;
  font-family: "Tajawal", sans-serif !important;
}
.secondary-green-background {
  background-color: var(--secondary-green-color) !important;
  font-family: "Tajawal", sans-serif !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
