.primary-btn {
    transition: background-color 0.3s ease-in-out;
    width: 100%;
    /* Smooth transition for color changes */

}

.primary-btn:hover {
    background-color: var(--secondary-pink-color) !important;
    color: white !important;
}

.primary-btn:active {
    background-color: var(--primary-blue-color) !important;
}